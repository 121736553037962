'use strict'
const _ = require('lodash')
const coreUtilsLib = require('santa-core-utils')

const fontUtils = coreUtilsLib.fonts

function getPropertySource(paramValue) {
    return /^(font|color)_[0-9]+$/.test(paramValue) ? 'theme' : 'value'
}

function calcFontFamilyForPropertySource(propertyValue, propertySource, generalTheme) {
    let fontFamily = ''
    if (propertySource === 'value') {
        fontFamily = fontUtils.parseFontStr(propertyValue).family.toLowerCase()
    } else if (propertySource === 'theme') {
        fontFamily = fontUtils.getFontFamilyByStyleId(generalTheme, propertyValue)
    }
    return fontFamily ? fontFamily.replace(/\u0000$/, '') : fontFamily //eslint-disable-line no-control-regex
}

function getFontFamilyFromStyleProperties(propertyObject, paramName, skin, generalTheme) {
    const propertyValue = propertyObject[paramName] || skin.paramsDefaults[paramName]
    const propertySource = getPropertySource(propertyValue)
    return calcFontFamilyForPropertySource(propertyValue, propertySource, generalTheme)
}

function getFonts(fontParamNames, propertyObject, skin, generalTheme) {
    const fonts = []
    _.forEach(fontParamNames, function (paramName) {
        const fontFamily = getFontFamilyFromStyleProperties(propertyObject, paramName, skin, generalTheme)
        if (fontFamily) {
            fonts.push(fontFamily)
        }
    })
    return fonts
}

function extractFontFamily(propertyObject) {
    const fontFamily = 'fontFamily'
    const propertyValue = propertyObject[fontFamily]
    const propertySource = getPropertySource(propertyValue)
    return propertyValue && propertySource === 'value' ? propertyValue : undefined
}

function getFontsFromSkin(generalTheme, skin, styleData, getSkin) {
    let fonts = []
    if (skin.exports) {
        fonts = _(skin.exports)
            .pickBy(childComp => childComp.skin && getSkin(childComp.skin))
            .flatMap(childComp => getFontsFromSkin(generalTheme, getSkin(childComp.skin), styleData, getSkin))
            .value()
    }
    if (skin.params) {
        const fontParamNames = _.keys(_.omitBy(skin.params, value => value !== 'FONT'))
        if (_.has(styleData, 'style.properties')) {
            const fontsArray = getFonts(fontParamNames, styleData.style.properties, skin, generalTheme)
            if (fontsArray) {
                fonts.push(...fontsArray)
            }
        } else if (styleData.values) {
            _.forEach(styleData.values, styleDataVal => {
                let fontsArray
                if (_.has(styleDataVal, 'style.properties')) {
                    fontsArray = getFonts(fontParamNames, styleDataVal.style.properties, skin, generalTheme)
                } else if (_.has(styleDataVal, 'ref.style.properties')) {
                    fontsArray = getFonts(fontParamNames, styleDataVal.ref.style.properties, skin, generalTheme)
                }
                if (fontsArray) {
                    fonts.push(...fontsArray)
                }
            })
        }
    } else if (styleData.skin === 'wysiwyg.viewer.skins.WRichTextNewSkin' && styleData.values) {
        _.forEach(styleData.values, styleDataVal => {
            let fontFamily
            if (_.has(styleDataVal, 'style.properties')) {
                fontFamily = extractFontFamily(styleDataVal.style.properties)
            } else if (_.has(styleDataVal, 'ref.style.properties')) {
                fontFamily = extractFontFamily(styleDataVal.ref.style.properties)
            }
            if (fontFamily) {
                fonts.push(fontFamily)
            }
        })
    }

    return fonts
}

function collectFontsFromLoadedCompStyles(styleItems, generalTheme, getSkin) {
    return _.reduce(styleItems, (collectedFonts, styleItem, styleId) => {
        const skin = getSkin(styleItem ? styleItem.skin : styleId)
        if (skin) {
            return collectedFonts.concat(getFontsFromSkin(generalTheme, skin, styleItem, getSkin))
        }

        return collectedFonts
    }, [])
}

module.exports = collectFontsFromLoadedCompStyles